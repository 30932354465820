<template>
	<div class="error-403">
		<v-container class="d-flex flex-column justify-center">
			<div class="display-3 mt-5">
				403. Нет прав на просмотр данной страницы.
			</div>
			<div class="grey--text lighten-5 mt-8">
				Страница, которую вы пытаетесь получить, существует, но она не
				предназначена для Вашего использования.
			</div>
			<div class="paragraph-text mt-5">
				Попробуйте вернуться на главную страницу и повторить свое действие. Или
				обратитесь к администрации сайта, чтобы исправить данное недоразумение.
			</div>
			<div class="d-flex mt-5">
				<Button class="mr-4" @click.native="$router.push('/')">
					Главная
				</Button>
				<Button text @click.native="$router.push('/logout')">
					Выйти из аккаунта
				</Button>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "Error403",
};
</script>

<style lang="scss" scoped>
.error-403 {
	height: 100vh;
}
::v-deep .container {
	height: 100% !important;
}
</style>
